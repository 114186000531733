<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="编号">
              <a-input v-model="queryParam.number" placeholder="编号" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="名称">
              <a-input v-model="queryParam.name" placeholder="名称" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleAdd">新建</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 1500 }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 120px">
        {{ (text / 1).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDetail(record)">详情</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="handleRemark(record)">备注</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="handleClient(record)">客户</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="handleEdit(record)">编辑</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm
                  title="确定要删除么？"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="handleDelet(record)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { project_list, project_create, project_delete } from '@/api/trade_project'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '主体企业',
          ellipsis: true,
          width: 200,
          dataIndex: 'host_corporation'
        },
        {
          title: '交易品种',
          ellipsis: true,
          width: 120,
          dataIndex: 'category'
        },
        {
          title: '编号',
          ellipsis: true,
          width: 150,
          dataIndex: 'number'
        },
        {
          title: '名称',
          ellipsis: true,
          width: 150,
          dataIndex: 'name'
        },
        {
          title: '信用',
          dataIndex: 'credit',
          width: 150,
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        },
        {
          title: '现金',
          dataIndex: 'cash',
          width: 150,
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        },
        {
          title: '起止时间',
          dataIndex: 'start_date',
          ellipsis: true,
          width: 180,
          customRender: (text, record) => text === '1970-01-01' ? '' : text + '到' + record.end_date
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return project_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      const date = new Date()
      this.mdl = { code: date.getFullYear() + '' + (date.getMonth() + 1) + date.getDay() }
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/project/' + record.id })
    },
    handleClient (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/project/' + record.id + '/client', query: { record: record.host_corporation } })
    },
    handleRemark (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/project/' + record.id + '/remark', query: { record: record.host_corporation } })
    },
    handleDetail (record) {
      this.$router.push({ path: '/trade/project/' + record.id + '/detail', query: { record: record.id, host_corporation_id: record.host_corporation_id } })
    },
    handleDelet (record) {
      project_delete(record.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleOk (date) {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          values.start_date = date.start_date
          values.end_date = date.end_date
          values.date = undefined
          project_create(values).then(res => {
            // 刷新表格
            if (res.code === 1000) {
              this.visible = false
              form.resetFields()
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
