import request from '@/utils/request'

const tradeClientApi = {
  trade_project_list: '/trade/project/',
  trade_project_create: '/trade/project/',
  trade_project_update: '/trade/project/',
  trade_project_delete: '/trade/project/',
  trade_project_detail: '/trade/project_detail/'
}

/**
 * 列表
 */
export function project_list (parameter) {
  return request({
    url: tradeClientApi.trade_project_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function project_create (parameter) {
  return request({
    url: tradeClientApi.trade_project_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function project_update (parameter, project_id) {
  return request({
    url: tradeClientApi.trade_project_update + project_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function project_delete (project_id) {
  return request({
    url: tradeClientApi.trade_project_delete + project_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function project_detail (parameter) {
  return request({
    url: tradeClientApi.trade_project_detail,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
