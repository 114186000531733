import request from '@/utils/request'

const tradeCategoryApi = {
  trade_category_list: '/trade/category/',
  trade_category_create: '/trade/category/',
  trade_category_update: '/trade/category/',
  trade_category_delete: '/trade/category/'
}

/**
 * 列表
 */
export function category_list (parameter) {
  return request({
    url: tradeCategoryApi.trade_category_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function category_create (parameter) {
  return request({
    url: tradeCategoryApi.trade_category_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function category_update (parameter, category_id) {
  return request({
    url: tradeCategoryApi.trade_category_update + category_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function category_delete (category_id) {
  return request({
    url: tradeCategoryApi.trade_category_delete + category_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
