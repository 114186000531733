<template>
  <a-card :bordered="false">
    <div>
      <a-form :form="form" >
        <a-form-item label="编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['number']" disabled/>
        </a-form-item>
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item label="信用" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number :precision="2" style="width: 50%" v-decorator="['credit', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
        </a-form-item>
        <a-form-item label="现金" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number :precision="2" style="width: 50%" v-decorator="['cash', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
        </a-form-item>
        <a-form-item label="起止时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-range-picker
            @change="onDateChange"
            v-decorator="['date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
          />
        </a-form-item>
        <a-form-item v-bind="buttonCol">
          <a-row>
            <a-col span="6">
              <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
            </a-col>
            <a-col span="10">
              <a-button @click="handleGoBack">返回</a-button>
            </a-col>
            <a-col span="8"></a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </div>
  </a-card>
</template>

<script>
import moment from 'moment'
import pick from 'lodash.pick'
import { project_update, project_list } from '@/api/trade_project'
const fields = ['number', 'name', 'credit', 'cash', 'date']
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      data: {},
      dateFormat: 'YYYY-MM-DD',
      start_date: '',
      end_date: '',
      date_change: false
    }
  },
  mounted () {
    fields.forEach(v => this.form.getFieldDecorator(v))
    console.log('-------------------', this.$route.params.id)
    project_list({ id: this.$route.params.id })
      .then(res => {
        console.log(res.data, '我是数据列表@')
        const list = res.data.entries
        if (list && list.length > 0) {
          this.data = list[0]
          this.id = this.data.id
          if (this.data.start_date === '1970-01-01') {
            this.start_date = ''
          } else {
            this.start_date = moment(this.data.start_date, this.dateFormat)
          }
          if (this.data.end_date === '1970-01-01') {
           this.end_date = ''
          } else {
            this.end_date = moment(this.data.end_date, this.dateFormat)
          }
          this.data.date = [this.start_date, this.end_date]
          this.form.setFieldsValue(pick(this.data, fields))
        }
      })
  },
  methods: {
    moment,
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          if (this.date_change) {
            values.start_date = this.start_date
            values.end_date = this.end_date
          } else {
            values.start_date = this.data.start_date
            values.end_date = this.data.end_date
          }
          values.date = undefined
          project_update(values, this.id)
            .then((res) => {
              if (res.code === 1000) {
                this.handleGoBack()
                console.log(res, '修改成功')
              }
            })
        }
      })
    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['id', 'name', 'credit', 'cash', 'date'])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    },
    onDateChange (date, dateString) {
      console.log(date, dateString)
      this.start_date = dateString[0]
      this.end_date = dateString[1]
      this.date_change = true
    }
  }
}
</script>
